<template>
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

        <div class="container-fluid py-4">
            <div class="row">
                <div class=" mb-xl-0 mb-4">
                    <div class="card bg-transparent shadow-xl">
                        <div class="overflow-hidden position-relative border-radius-xl">
                            <div class="card-body position-relative z-index-1 p-3">
                                <div class="d-flex flex-row justify-content-between">
                                    <div>
                                        <p class="text-dark text-sm opacity-8 mb-0">Current Balance</p>
                                        <h5 class="text-dark mb-2 pb-2">R {{auth.user.balance | currency}}</h5>
                                    </div>
                                    <div>
                                        <router-link to="/" class="btn bg-gradient-primary mb-0 mt-1"><i class="material-icons text-sm">home</i>&nbsp;&nbsp;Home</router-link>
                                    </div>
                                </div>
                                <div class="d-flex align-end">
                                    <span class=" d-flex text-dark text-sm opacity-8 mb-0 pt-1">Please enter top up amount of</span>
                                    <h5 :style="color" class="px-2">R 50</h5>
                                    <span class=" d-flex text-dark text-sm opacity-8 mb-0 pt-1">or more!</span>
                                </div>
                                <div class="d-flex">
                                    <div class="input-group input-group-outline mb-3">
                                        <input class="form-control" type="number" inputmode="numeric" v-model="paymentAmount" min="50" />
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="text-dark text-sm opacity-8 mb-0">Balance after top up</p>
                                        <h5 class="text-grey mb-2 pb-2">R {{(parseFloat(auth.user.balance) + parseFloat(paymentAmount||0)) | currency}}</h5>
                                    </div>
                                </div>
                            </div>


                            <div class="card-header pb-0 px-3 pt-1">
                                <h6 class="mb-0">Payment Method</h6>
                            </div>
                            <div class="card-body pt-4 p-3">
                                <div class="list-group">
                                    <template v-for="(provider, idx) in paymentMethods">
                                        <div class="border-0 row p-4 mb-4 mx-1 bg-gray-200 border-radius-lg" :key="`method-${idx}`">
                                            <div class="d-flex flex-column">
                                                <h6 class="mb-3 text-sm ">{{provider.title}}</h6>
                                                <div class="d-flex flex-row justify-content-start mb-2">
                                                    <img :src="`/images/logos/${provider.image}`" width="150" />
                                                </div>
                                                <span class="mb-2 text-xs">{{provider.description}}</span>
                                                <div class="d-flex flex-row justify-content-start py-2" v-if="provider.extraImage">
                                                    <img :src="`/images/logos/${provider.extraImage}`" height="20" />
                                                </div>
                                                <div class="d-flex flex-row">
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <button v-if="canPay"
                                                                class="btn bg-gradient-dark mb-0 btn-pay"
                                                                v-on:click="setPaymentAndSubmit(provider.method)"
                                                                style="width:100%;">
                                                            Pay with {{provider.name}}
                                                        </button>
                                                        <button v-else 
                                                                class="btn bg-gradient-dark mb-0 btn-pay"
                                                                disabled
                                                                style="width:100%;">
                                                            <span class="text--error">
                                                                Please enter a valid amount
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form style="position:absolute;left:-9999px" method="POST" action="/Payment/SubmitPaymentRequest" ref="payForm">
                <input name="paymentProvider" v-model="paymentProvider" />
                <input name="amount" v-model="paymentAmount" />
                <input name="token" v-model="auth.token" />
            </form>
            <footer class="footer py-4">
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-lg-between">
                        <div class="cal-lg-6 mb-lg-0 mb-4">
                            <div class="copyright text-center text-sm text-muted taxt-lg-start">
                                ⚡ By
                                <a href="https://www.wizebooks.co.za" class="font-weight-bold" target="_blank">Wize Books</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </main>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data: function () {
            return {
                loading: false,
                paymentAmount: 50,
                paymentProvider: null,
                paymentMethods: [
                    { title: 'Instant EFT', name: 'Ozow', method: 'ozow', image: 'ozow.png', extraImage: 'ozow_extra.png', description: 'Ozow allows you to pay for your order by EFT instantly and securely.' },
                    { title: 'Credit- or Debit Card', name: 'DPO Paygate', method: 'paygate', image: 'paygate.png', extraImage: 'paygate_extra.png', description: 'DPO is a payment gateway that accepts all major credit and debit cards including VISA and MasterCard.' },
                    { title: 'On Credit', name: 'Mobicred', method: 'mobicred', image: 'mobicred.png', description: 'Mobicred is a simple & convenient credit facility that allows you to safely shop online.' }
                ],
            };
        },
        methods: {
            setPaymentAndSubmit: function (method) {
                this.paymentProvider = method;
                console.log(this.paymentProvider)
                setTimeout(() => {
                    this.$refs.payForm.submit();
                }, 1)
            }
        },
        components: {
        },
        mounted: function () {
        },
        computed: {
            ...mapState(['auth']),
            canPay: function () {
                return this.paymentAmount <= 10000 & this.paymentAmount >= 50;
            },
            color: function () {
                return this.canPay ? 'color:var(--bs-success)' : 'color:var(--bs-danger)';
            }
        },

    }
</script>
<style>
    .truncate {
        width: 75vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text--error {
        color: #F44336 !important;
    }

    .text--success {
        color: #4CAF50 !important;
    }
</style>